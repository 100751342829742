var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      staticClass: "tGroupPerson",
      attrs: { title: _vm.editTitle, "mask-closable": false },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "Form",
        {
          ref: "tGroupPersonForm",
          staticClass: "form",
          attrs: {
            model: _vm.tGroupPersonForm,
            rules: _vm.tGroupPersonFormRule,
            "label-width": 120,
          },
        },
        [
          _c(
            "Tabs",
            { staticClass: "tabs" },
            [
              _vm.physicalType == "健康体检" || _vm.physicalType == "从业体检"
                ? _c(
                    "TabPane",
                    { attrs: { label: "基本信息" } },
                    [
                      _c(
                        "Row",
                        [
                          _c(
                            "Col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "FormItem",
                                {
                                  attrs: {
                                    label: "人员姓名",
                                    prop: "personName",
                                  },
                                },
                                [
                                  _c("Input", {
                                    attrs: {
                                      disabled: _vm.disabled,
                                      type: "text",
                                      maxlength: 50,
                                      placeholder: "请输入人员姓名",
                                    },
                                    model: {
                                      value: _vm.tGroupPersonForm.personName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tGroupPersonForm,
                                          "personName",
                                          $$v
                                        )
                                      },
                                      expression: "tGroupPersonForm.personName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "Col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "FormItem",
                                { attrs: { label: "证件号码" } },
                                [
                                  _c("Input", {
                                    attrs: {
                                      disabled: _vm.disabled,
                                      type: "text",
                                      placeholder: "请输入证件号码",
                                    },
                                    on: { input: _vm.idCardChange },
                                    model: {
                                      value: _vm.tGroupPersonForm.idCard,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tGroupPersonForm,
                                          "idCard",
                                          $$v
                                        )
                                      },
                                      expression: "tGroupPersonForm.idCard",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "Row",
                        [
                          _c(
                            "Col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "FormItem",
                                { attrs: { label: "性别", prop: "sex" } },
                                [
                                  _c(
                                    "RadioGroup",
                                    {
                                      model: {
                                        value: _vm.tGroupPersonForm.sex,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.tGroupPersonForm,
                                            "sex",
                                            $$v
                                          )
                                        },
                                        expression: "tGroupPersonForm.sex",
                                      },
                                    },
                                    [
                                      _c(
                                        "Radio",
                                        {
                                          attrs: {
                                            disabled: _vm.disabled,
                                            label: "男",
                                          },
                                        },
                                        [_vm._v("男")]
                                      ),
                                      _c(
                                        "Radio",
                                        {
                                          attrs: {
                                            disabled: _vm.disabled,
                                            label: "女",
                                          },
                                        },
                                        [_vm._v("女")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "Col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "FormItem",
                                { attrs: { label: "出生日期" } },
                                [
                                  _c("DatePicker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      type: "date",
                                      transfer: "",
                                      disabled: _vm.disabled,
                                      placeholder: "请选择",
                                      format: "yyyy-MM-dd",
                                    },
                                    model: {
                                      value: _vm.tGroupPersonForm.birth,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tGroupPersonForm,
                                          "birth",
                                          $$v
                                        )
                                      },
                                      expression: "tGroupPersonForm.birth",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "Row",
                        [
                          _c(
                            "Col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "FormItem",
                                { attrs: { label: "年龄", prop: "age" } },
                                [
                                  _c("Input", {
                                    attrs: {
                                      disabled: _vm.disabled,
                                      type: "text",
                                      placeholder: "请输入年龄",
                                    },
                                    model: {
                                      value: _vm.tGroupPersonForm.age,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tGroupPersonForm,
                                          "age",
                                          $$v
                                        )
                                      },
                                      expression: "tGroupPersonForm.age",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "Col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "FormItem",
                                {
                                  attrs: { label: "手机号码", prop: "mobile" },
                                },
                                [
                                  _c("Input", {
                                    attrs: {
                                      disabled: _vm.disabled,
                                      type: "text",
                                      placeholder: "请输入手机号码",
                                    },
                                    model: {
                                      value: _vm.tGroupPersonForm.mobile,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tGroupPersonForm,
                                          "mobile",
                                          $$v
                                        )
                                      },
                                      expression: "tGroupPersonForm.mobile",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "Row",
                        [
                          _vm.tGroupPersonForm.id
                            ? _c(
                                "Col",
                                { attrs: { span: "12" } },
                                [
                                  _c(
                                    "FormItem",
                                    {
                                      attrs: {
                                        label: "单位名称",
                                        prop: "groupUnitId",
                                      },
                                    },
                                    [
                                      _vm.physicalType == "健康体检"
                                        ? _c(
                                            "Select",
                                            {
                                              staticClass: "input",
                                              attrs: {
                                                filterable: true,
                                                disabled: _vm.disabled,
                                                "label-in-value": "",
                                                transfer: "",
                                              },
                                              on: {
                                                "on-select":
                                                  _vm.onOrderSelectChange,
                                              },
                                              model: {
                                                value:
                                                  _vm.tGroupPersonForm.orderId,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.tGroupPersonForm,
                                                    "orderId",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "tGroupPersonForm.orderId",
                                              },
                                            },
                                            _vm._l(
                                              _vm.orderArr,
                                              function (item, index) {
                                                return _c(
                                                  "Option",
                                                  {
                                                    key: index,
                                                    attrs: {
                                                      value: item.id,
                                                      tag: item.groupUnitId,
                                                      label: item.groupUnitName,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.groupUnitName +
                                                            "-" +
                                                            item.orderCode
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.physicalType == "从业体检"
                                        ? _c(
                                            "Select",
                                            {
                                              staticClass: "input",
                                              attrs: {
                                                disabled: "",
                                                filterable: true,
                                                disabled: _vm.disabled,
                                                "label-in-value": "",
                                                transfer: "",
                                              },
                                              on: {
                                                "on-select":
                                                  _vm.onOrderSelectChange,
                                              },
                                              model: {
                                                value:
                                                  _vm.tGroupPersonForm.unitId,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.tGroupPersonForm,
                                                    "unitId",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "tGroupPersonForm.unitId",
                                              },
                                            },
                                            _vm._l(
                                              _vm.unitArr,
                                              function (item, index) {
                                                return _c(
                                                  "Option",
                                                  {
                                                    key: index,
                                                    attrs: {
                                                      value: item.id,
                                                      tag: item.groupUnitId,
                                                      label: item.name,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.name) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.tGroupPersonForm.id
                            ? _c(
                                "Col",
                                { attrs: { span: "12" } },
                                [
                                  _c(
                                    "FormItem",
                                    {
                                      attrs: { label: "分组", prop: "groupId" },
                                    },
                                    [
                                      _c(
                                        "Select",
                                        {
                                          attrs: {
                                            transfer: "",
                                            disabled: _vm.disabled,
                                            placeholder: "请选择分组",
                                          },
                                          on: { "on-change": _vm.groupChange },
                                          model: {
                                            value: _vm.tGroupPersonForm.groupId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.tGroupPersonForm,
                                                "groupId",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "tGroupPersonForm.groupId",
                                          },
                                        },
                                        _vm._l(
                                          _vm.groupList,
                                          function (item, index) {
                                            return _c(
                                              "Option",
                                              {
                                                key: index,
                                                attrs: {
                                                  value: item.id,
                                                  tag: item,
                                                },
                                              },
                                              [_vm._v(_vm._s(item.name))]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "Col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "FormItem",
                                { attrs: { label: "结婚状况" } },
                                [
                                  _c(
                                    "Select",
                                    {
                                      attrs: {
                                        disabled: _vm.disabled,
                                        transfer: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.tGroupPersonForm.isMarry,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.tGroupPersonForm,
                                            "isMarry",
                                            $$v
                                          )
                                        },
                                        expression: "tGroupPersonForm.isMarry",
                                      },
                                    },
                                    [
                                      _c(
                                        "Option",
                                        { attrs: { value: "未婚" } },
                                        [_vm._v("未婚")]
                                      ),
                                      _c(
                                        "Option",
                                        { attrs: { value: "已婚" } },
                                        [_vm._v("已婚")]
                                      ),
                                      _c(
                                        "Option",
                                        { attrs: { value: "离异" } },
                                        [_vm._v("离异")]
                                      ),
                                      _c(
                                        "Option",
                                        { attrs: { value: "丧偶" } },
                                        [_vm._v("丧偶")]
                                      ),
                                      _c(
                                        "Option",
                                        { attrs: { value: "其他" } },
                                        [_vm._v("其他")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.physicalType == "从业体检"
                            ? _c(
                                "Col",
                                { attrs: { span: "12" } },
                                [
                                  _c(
                                    "FormItem",
                                    {
                                      attrs: { label: "民族", prop: "nation" },
                                    },
                                    [
                                      _c(
                                        "Select",
                                        {
                                          attrs: {
                                            transfer: "",
                                            placeholder: "请选择",
                                          },
                                          model: {
                                            value: _vm.tGroupPersonForm.nation,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.tGroupPersonForm,
                                                "nation",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "tGroupPersonForm.nation",
                                          },
                                        },
                                        _vm._l(
                                          _vm.nationArr,
                                          function (item, i) {
                                            return _c(
                                              "Option",
                                              { attrs: { value: item.value } },
                                              [_vm._v(_vm._s(item.title))]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.physicalType == "职业体检" || _vm.physicalType == "放射体检"
                ? _c(
                    "TabPane",
                    { attrs: { label: "基本信息" } },
                    [
                      _c(
                        "Row",
                        [
                          _c(
                            "Col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "FormItem",
                                {
                                  attrs: {
                                    label: "人员姓名",
                                    prop: "personName",
                                  },
                                },
                                [
                                  _c("Input", {
                                    attrs: {
                                      disabled: _vm.disabled,
                                      type: "text",
                                      maxlength: 50,
                                      placeholder: "请输入人员姓名",
                                    },
                                    model: {
                                      value: _vm.tGroupPersonForm.personName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tGroupPersonForm,
                                          "personName",
                                          $$v
                                        )
                                      },
                                      expression: "tGroupPersonForm.personName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "Col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "FormItem",
                                { attrs: { label: "证件号码" } },
                                [
                                  _c("Input", {
                                    attrs: {
                                      disabled: _vm.disabled,
                                      type: "text",
                                      placeholder: "请输入证件号码",
                                    },
                                    on: { input: _vm.idCardChange },
                                    model: {
                                      value: _vm.tGroupPersonForm.idCard,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tGroupPersonForm,
                                          "idCard",
                                          $$v
                                        )
                                      },
                                      expression: "tGroupPersonForm.idCard",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "Row",
                        [
                          _c(
                            "Col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "FormItem",
                                { attrs: { label: "性别", prop: "sex" } },
                                [
                                  _c(
                                    "RadioGroup",
                                    {
                                      model: {
                                        value: _vm.tGroupPersonForm.sex,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.tGroupPersonForm,
                                            "sex",
                                            $$v
                                          )
                                        },
                                        expression: "tGroupPersonForm.sex",
                                      },
                                    },
                                    [
                                      _c(
                                        "Radio",
                                        {
                                          attrs: {
                                            disabled: _vm.disabled,
                                            label: "男",
                                          },
                                        },
                                        [_vm._v("男")]
                                      ),
                                      _c(
                                        "Radio",
                                        {
                                          attrs: {
                                            disabled: _vm.disabled,
                                            label: "女",
                                          },
                                        },
                                        [_vm._v("女")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "Col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "FormItem",
                                { attrs: { label: "出生日期" } },
                                [
                                  _c("DatePicker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      type: "date",
                                      disabled: _vm.disabled,
                                      placeholder: "请选择",
                                      format: "yyyy-MM-dd",
                                    },
                                    model: {
                                      value: _vm.tGroupPersonForm.birth,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tGroupPersonForm,
                                          "birth",
                                          $$v
                                        )
                                      },
                                      expression: "tGroupPersonForm.birth",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "Row",
                        [
                          _c(
                            "Col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "FormItem",
                                { attrs: { label: "年龄", prop: "age" } },
                                [
                                  _c("Input", {
                                    attrs: {
                                      disabled: _vm.disabled,
                                      type: "text",
                                      placeholder: "请输入年龄",
                                    },
                                    model: {
                                      value: _vm.tGroupPersonForm.age,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tGroupPersonForm,
                                          "age",
                                          $$v
                                        )
                                      },
                                      expression: "tGroupPersonForm.age",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "Col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "FormItem",
                                { attrs: { label: "结婚状况" } },
                                [
                                  _c(
                                    "Select",
                                    {
                                      attrs: {
                                        disabled: _vm.disabled,
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.tGroupPersonForm.isMarry,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.tGroupPersonForm,
                                            "isMarry",
                                            $$v
                                          )
                                        },
                                        expression: "tGroupPersonForm.isMarry",
                                      },
                                    },
                                    [
                                      _c(
                                        "Option",
                                        { attrs: { value: "未婚" } },
                                        [_vm._v("未婚")]
                                      ),
                                      _c(
                                        "Option",
                                        { attrs: { value: "已婚" } },
                                        [_vm._v("已婚")]
                                      ),
                                      _c(
                                        "Option",
                                        { attrs: { value: "离异" } },
                                        [_vm._v("离异")]
                                      ),
                                      _c(
                                        "Option",
                                        { attrs: { value: "丧偶" } },
                                        [_vm._v("丧偶")]
                                      ),
                                      _c(
                                        "Option",
                                        { attrs: { value: "其他" } },
                                        [_vm._v("其他")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "Row",
                        [
                          _c(
                            "Col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "FormItem",
                                {
                                  attrs: { label: "手机号码", prop: "mobile" },
                                },
                                [
                                  _c("Input", {
                                    attrs: {
                                      disabled: _vm.disabled,
                                      type: "text",
                                      placeholder: "请输入手机号码",
                                    },
                                    model: {
                                      value: _vm.tGroupPersonForm.mobile,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tGroupPersonForm,
                                          "mobile",
                                          $$v
                                        )
                                      },
                                      expression: "tGroupPersonForm.mobile",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "Col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "FormItem",
                                {
                                  attrs: {
                                    label: "危害因素",
                                    prop: "hazardFactors",
                                  },
                                },
                                [
                                  _c("Input", {
                                    attrs: {
                                      disabled: _vm.disabled,
                                      type: "textarea",
                                      readonly: "",
                                    },
                                    on: { "on-focus": _vm.handleHarmSelect },
                                    model: {
                                      value:
                                        _vm.tGroupPersonForm.hazardFactorsText,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tGroupPersonForm,
                                          "hazardFactorsText",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "tGroupPersonForm.hazardFactorsText",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.tGroupPersonForm &&
                      _vm.tGroupPersonForm.hazardFactors &&
                      (_vm.tGroupPersonForm.hazardFactors.indexOf("110999") >
                        -1 ||
                        _vm.tGroupPersonForm.hazardFactors.indexOf("120999") >
                          -1 ||
                        _vm.tGroupPersonForm.hazardFactors.indexOf("130999") >
                          -1 ||
                        _vm.tGroupPersonForm.hazardFactors.indexOf("140999") >
                          -1 ||
                        _vm.tGroupPersonForm.hazardFactors.indexOf("150999") >
                          -1 ||
                        _vm.tGroupPersonForm.hazardFactors.indexOf("160999") >
                          -1)
                        ? _c(
                            "Row",
                            [
                              _c(
                                "Col",
                                { attrs: { span: "12" } },
                                [
                                  _c(
                                    "FormItem",
                                    {
                                      attrs: {
                                        label: "其他危害因素",
                                        prop: "otherHazardFactors",
                                      },
                                    },
                                    [
                                      _c("Input", {
                                        attrs: {
                                          disabled: _vm.disabled,
                                          type: "text",
                                          placeholder:
                                            "请输入其他危害因素名称，多个逗号“，”分隔",
                                        },
                                        model: {
                                          value:
                                            _vm.tGroupPersonForm
                                              .otherHazardFactors,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.tGroupPersonForm,
                                              "otherHazardFactors",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "tGroupPersonForm.otherHazardFactors",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "Row",
                        [
                          _c(
                            "Col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "FormItem",
                                {
                                  attrs: {
                                    label: "工种名称",
                                    prop: "workTypeCode",
                                  },
                                },
                                [
                                  _c("Input", {
                                    attrs: {
                                      disabled: _vm.disabled,
                                      placeholder: "请选择工种",
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.handleWorkCode.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.tGroupPersonForm.workTypeText,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tGroupPersonForm,
                                          "workTypeText",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "tGroupPersonForm.workTypeText",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.tGroupPersonForm.workTypeCode &&
                          (_vm.tGroupPersonForm.workTypeCode.indexOf("0014") >
                            -1 ||
                            _vm.tGroupPersonForm.workTypeCode.indexOf("0033") >
                              -1 ||
                            _vm.tGroupPersonForm.workTypeCode.indexOf(
                              "999999"
                            ) > -1)
                            ? _c(
                                "Col",
                                { attrs: { span: "12" } },
                                [
                                  _c(
                                    "FormItem",
                                    {
                                      attrs: {
                                        label: "工种其他名称",
                                        prop: "workName",
                                      },
                                    },
                                    [
                                      _c("Input", {
                                        attrs: {
                                          disabled: _vm.disabled,
                                          type: "text",
                                          placeholder: "请输入工种其他名称",
                                        },
                                        model: {
                                          value: _vm.tGroupPersonForm.workName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.tGroupPersonForm,
                                              "workName",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "tGroupPersonForm.workName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "Row",
                        [
                          _c(
                            "Col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "FormItem",
                                {
                                  attrs: {
                                    label: "在岗状态",
                                    prop: "workStateCode",
                                  },
                                },
                                [
                                  _c(
                                    "Select",
                                    {
                                      attrs: {
                                        transfer: "",
                                        disabled: _vm.disabled,
                                        "label-in-value": "",
                                      },
                                      on: { "on-change": _vm.workChange },
                                      model: {
                                        value:
                                          _vm.tGroupPersonForm.workStateCode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.tGroupPersonForm,
                                            "workStateCode",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "tGroupPersonForm.workStateCode",
                                      },
                                    },
                                    _vm._l(
                                      _vm.workStateCodeArr,
                                      function (item, index) {
                                        return _c(
                                          "Option",
                                          {
                                            key: item.id,
                                            attrs: { value: item.value },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.title) + " "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          false
                            ? _c(
                                "Col",
                                { attrs: { span: "12" } },
                                [
                                  _c(
                                    "FormItem",
                                    {
                                      attrs: {
                                        label: "接害开始日期",
                                        prop: "exposureStartDate",
                                      },
                                    },
                                    [
                                      _c("DatePicker", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          type: "date",
                                          placeholder: "请选择",
                                          disabled: _vm.disabled,
                                          transfer: "",
                                          format: "yyyy-MM-dd",
                                        },
                                        on: { "on-change": _vm.expDateChange },
                                        model: {
                                          value:
                                            _vm.tGroupPersonForm
                                              .exposureStartDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.tGroupPersonForm,
                                              "exposureStartDate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "tGroupPersonForm.exposureStartDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "Col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "FormItem",
                                {
                                  attrs: { label: "监测类型", prop: "jcType" },
                                },
                                [
                                  _c(
                                    "Select",
                                    {
                                      attrs: {
                                        disabled: _vm.disabled,
                                        transfer: "",
                                      },
                                      model: {
                                        value: _vm.tGroupPersonForm.jcType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.tGroupPersonForm,
                                            "jcType",
                                            $$v
                                          )
                                        },
                                        expression: "tGroupPersonForm.jcType",
                                      },
                                    },
                                    [
                                      _c("Option", { attrs: { value: "1" } }, [
                                        _vm._v("常规监测"),
                                      ]),
                                      _c("Option", { attrs: { value: "2" } }, [
                                        _vm._v("主动监测"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.tGroupPersonForm.id &&
                          _vm.tGroupPersonForm.isPass != 1
                            ? _c(
                                "Col",
                                { attrs: { span: "12" } },
                                [
                                  _c(
                                    "FormItem",
                                    { attrs: { label: "登记时间" } },
                                    [
                                      _c("DatePicker", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          type: "datetime",
                                          placeholder: "请选择",
                                          disabled: _vm.disabled,
                                          transfer: "",
                                          format: "yyyy-MM-dd HH:mm:ss",
                                        },
                                        model: {
                                          value:
                                            _vm.tGroupPersonForm.registDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.tGroupPersonForm,
                                              "registDate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "tGroupPersonForm.registDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.tGroupPersonForm.id
                            ? _c(
                                "Col",
                                { attrs: { span: "12" } },
                                [
                                  _c(
                                    "FormItem",
                                    {
                                      attrs: {
                                        label: "单位名称",
                                        prop: "groupUnitId",
                                      },
                                    },
                                    [
                                      _c(
                                        "Select",
                                        {
                                          staticClass: "input",
                                          attrs: {
                                            filterable: true,
                                            disabled: _vm.disabled,
                                            "label-in-value": "",
                                            transfer: "",
                                          },
                                          on: {
                                            "on-select":
                                              _vm.onOrderSelectChange,
                                          },
                                          model: {
                                            value: _vm.tGroupPersonForm.orderId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.tGroupPersonForm,
                                                "orderId",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "tGroupPersonForm.orderId",
                                          },
                                        },
                                        _vm._l(
                                          _vm.orderArr,
                                          function (item, index) {
                                            return _c(
                                              "Option",
                                              {
                                                key: index,
                                                attrs: {
                                                  value: item.id,
                                                  tag: item.groupUnitId,
                                                  label: item.groupUnitName,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      item.groupUnitName +
                                                        "-" +
                                                        item.orderCode
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "Row",
                        [
                          _vm.tGroupPersonForm.id
                            ? _c(
                                "Col",
                                { attrs: { span: "24" } },
                                [
                                  _c(
                                    "FormItem",
                                    {
                                      attrs: { label: "分组", prop: "groupId" },
                                    },
                                    [
                                      _c(
                                        "Select",
                                        {
                                          attrs: {
                                            disabled: _vm.disabled,
                                            "label-in-value": "",
                                            transfer: "",
                                            placeholder: "请选择分组",
                                          },
                                          on: { "on-change": _vm.groupChange },
                                          model: {
                                            value: _vm.tGroupPersonForm.groupId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.tGroupPersonForm,
                                                "groupId",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "tGroupPersonForm.groupId",
                                          },
                                        },
                                        _vm._l(
                                          _vm.groupList,
                                          function (item, index) {
                                            return _c(
                                              "Option",
                                              {
                                                key: index,
                                                attrs: {
                                                  value: item.id,
                                                  tag: item,
                                                },
                                              },
                                              [_vm._v(_vm._s(item.name))]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "Button",
                {
                  attrs: {
                    slot: "extra",
                    loading: _vm.readShow,
                    type: "primary",
                    icon: "md-add",
                  },
                  on: { click: _vm.readIdCard },
                  slot: "extra",
                },
                [_vm._v("读取二代身份证")]
              ),
            ],
            1
          ),
          _vm.spinShow
            ? _c("Spin", { attrs: { size: "large", fix: "" } })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "Button",
            {
              attrs: { type: "text" },
              on: {
                click: function ($event) {
                  _vm.show = false
                },
              },
            },
            [_vm._v("取消")]
          ),
          !_vm.disabled
            ? _c(
                "Button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.handelSubmit },
                },
                [_vm._v("提交")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("chooseWorkType", {
        on: { workCodeReback: _vm.workCodeReback },
        model: {
          value: _vm.workShow,
          callback: function ($$v) {
            _vm.workShow = $$v
          },
          expression: "workShow",
        },
      }),
      _c("harmDrawer", {
        attrs: {
          modalTitle: _vm.title,
          selectedHarmList: _vm.selectedHarmList,
          selectType: _vm.seltType,
        },
        on: { harmReback: _vm.harmReback },
        model: {
          value: _vm.harmShow,
          callback: function ($$v) {
            _vm.harmShow = $$v
          },
          expression: "harmShow",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }